import styled from 'styled-components';
import { Button } from '../../../../components'

const IconAndText = styled.div`
    display: flex;
    justify-content: center;
`;

const QuitButtonSection = styled.div`
    margin: 0.5vw;
    align-items: stretch;
    height: 2vw;
`;

const StyledQuitButton = styled(Button)`
    margin: 0;
    padding-right: 1vw;
    padding-left: 1vw;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.secondary};
    font-size: 1.5vw;

    div {
        margin-right: 0.5vw;
        margin-top: 0.1vw;
    }
`;

const ModalPanel = styled.div`
    width: 50vw;
    height: 50vh;
    position: absolute;
    top: 25vh;
    left: 25vw;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;

    h1 {
        color: ${props => props.theme.colors.primary};
    }
`;

const ModalButton = styled(Button)`
    flex: 1;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    line-height: 2vw;
    margin: 0.5vw;
    font-size: 1.5vw;
`;

const ModalTitle = styled.div`
    display: flex;
    margin-bottom: auto;
    justify-content: center;
`;

const ModalControls = styled.div`
    display: flex;
`;


export {
    IconAndText,
    StyledQuitButton,
    QuitButtonSection,
    ModalButton,
    ModalPanel,
    ModalTitle,
    ModalControls,
}
