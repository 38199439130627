import styled from 'styled-components';

const WaitToStart = styled.span`
    flex: 5;
    font-size: 3vw;
    align-self: center;
    color: ${props => props.theme.colors.primary};
`;

const StyledActionPanel = styled.div`
    flex: 5;
    font-size: 4rem;
    display: flex;
    align-self: center;
    flex-direction: column;
`;

const ActionPanelNameAndAction = styled.span`
    font-size: 6vh;
    text-align: center;
    color: ${props => props.theme.colors.primary};
`;

const ActionPanelDescription = styled.span`
    font-size: 4vh;
    text-align: center;
    color: ${props => props.theme.colors.primary};
`;

export {
    ActionPanelDescription,
    ActionPanelNameAndAction,
    StyledActionPanel,
    WaitToStart,
}
