import React from "react";
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg'


const Icon = ({path, color, size }) => (
    <ReactSVG
        src={path}
        beforeInjection={(svg) => {
            svg.setAttribute('width', size)
            svg.setAttribute('style', `fill: ${color}`)
        }}
    />
);

Icon.propTypes = {
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired
};

export default Icon;