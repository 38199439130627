import styled from 'styled-components';

const FakeInfoIcon = styled.div`
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    font-weight: bold;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
`;

const GoHomeLink = styled.div`
    margin-top: 5vw;
    display: flex;
    justify-content: center;

    a {
        color: ${props => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: row;
`;

const ModalControls = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Result = styled.h1`
    color: ${props => props.theme.colors.primary};
`;

const HeightButton = styled.button`
    border: none;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    margin: 0.1vw;
    cursor: pointer;
    width: 5vw;
    height: 2vw;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${props => props.theme.colors.disabled};
        cursor: default;
    }
`;

const ModalPanel = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    width: 50vw;
    margin-top: 10vh;
    padding: 2vw;
`;

const PageHeading = styled.h1`
    color: ${props => props.theme.colors.secondary};
`;

const PageContainer = styled.div`
    padding: 1vw;
`;

const Calculator = styled.div`
    color: ${props => props.theme.colors.secondary};
`;

const CalculatorInputs = styled.div`
    display: flex;
    flex-direction: row;

    span {
        margin-right: 0.5vw;
        height: 2vw;
        line-height: 2vw;
    }

    input {
        margin-right: 0.5vw;
    }
`;

const ModalImage = styled.div`
    margin-top: 1vw;
    display: flex;
    justify-content: flex-center;

    img {
        width: 100%;
        height: 100%;
    }
`;

export {
    FakeInfoIcon,
    GoHomeLink,
    ModalContent,
    ModalControls,
    Result,
    ModalPanel,
    ModalImage,
    HeightButton,
    PageContainer,
    PageHeading,
    Calculator,
    CalculatorInputs,
}
