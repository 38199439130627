import styled from 'styled-components';

const PlayerPill = styled.div`
    width: 19%;
    height: 1.5vw;
    line-height: 1.5vw;
    margin-right: 0.5%;
    margin-left: 0.5%;
    margin-bottom: 1%;
    border-radius: 0.5vw;
    font-size: 1vw;
    background-color: ${props => props.isActive ?
        props.theme.colors.active :
        props.theme.colors.primary
    };
    color: ${props => props.theme.colors.secondary};
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
        cursor: default;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }
`;

export {
    PlayerPill,
}
