import React from 'react';
import { Route } from 'react-router-dom';
import Height from '.';

const route = [
    <Route
        path="/height-calculator-tool"
        exact
        component={Height}
        key="/height-calculator-tool"
    />,
    <Route
        path="/height"
        exact
        component={Height}
        key="/height"
    />,
];

export default route;
