import React from 'react';
import PropTypes from 'prop-types';

import {
    WheelContainer,
    Spinner,
    WheelImage,
} from './styles';

const Wheel = ({ isSpinning, ActionImage, spinnerImg}) =>  (
    <WheelContainer>
        {isSpinning && (
            <Spinner>
                <WheelImage spinning image={spinnerImg} />
            </Spinner>
        )}
        {ActionImage && (
            ActionImage
        )}
    </WheelContainer>
)

Wheel.propTypes = {
    isSpinning: PropTypes.bool.isRequired,
    ActionImage: PropTypes.node,
    spinnerImg: PropTypes.string.isRequired,
}

export default Wheel;
