import styled from 'styled-components';

const Credits = styled.div`
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    h1 {
        color: ${props => props.theme.colors.secondary};
        text-align: center;
    }
`;

const DevCredit = styled.h2`
    color: ${props => props.theme.colors.secondary};
    text-align: center;
`;

const IconCredit = styled.div`
    color: ${props => props.theme.colors.secondary};
    text-align: center;

    a {
        color: ${props => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const GoHomeLink = styled.div`
    margin-top: 5vw;
    display: flex;
    justify-content: center;

    a {
        color: ${props => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const TheBeach = styled.div`
    margin-top: 1vw;

    img {
        width: 100%;
    }
`;

export {
    Credits,
    DevCredit,
    IconCredit,
    GoHomeLink,
    TheBeach,
}
