import styled from 'styled-components';

const Spinner = styled.div`
    background-color: rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

Spinner.displayName = 'Spinner';

const WheelContainer = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    width: 100%;
`;

const WheelImage = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;

    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${props => props.spinning && (`
        flex: 1;
        max-width: 30%;
        max-height: 75%;
        margin-left: auto;
        margin-right: auto;
        width: inherit;
        animation-name: spin;
        animation-duration: ${props.animationDuration ? props.animationDuration : '3'}000ms;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);;

        @keyframes spin {
            from {
                transform:rotate(0deg);
            }
            to {
                transform:rotate(${props.animationDuration ? props.animationDuration * 360 : '1440'}deg);
            }
        }

        @media (max-width: 768px) {
            max-height: 30%;
        }
    `)}
`;

export {
    Spinner,
    WheelContainer,
    WheelImage
}
