const dares = [
    (target, drinksText) => `Swap costume with ${target}.`,
    (target, drinksText) => `Swap costume with ${target}. Dress each other though, gotta look after those costumes...`,
    (target, drinksText) => `Let's bob for apples. Except the apples are whatever the group decides, and the water is beer.`,
    (target, drinksText) => `Tell us a scary story. ${drinksText} if no one is scared.`,
    (target, drinksText) => `Bump some sweets off someone. If its from a non-guest outside, everyone else does 2 shots.`,
    (target, drinksText) => `Speak like a vampire for 2 turns.`,
    (target, drinksText) => `Speak like a vampire for 3 turns.`,
    (target, drinksText) => `Speak like a vampire for 4 turns.`,
    (target, drinksText) => `Speak like a vampire for the rest of the game.`,
    (target, drinksText) => `Go trick or treating. If you come back with sweets, everyone else does ${drinksText}.`,
    (target, drinksText) => `Give ${target} a fright before your next turn. Failure means ${drinksText}, success means they do it.`,
    (target, drinksText) => `Give ${target} a fright before your next turn. Failure means 2 shots, success means they do it.`,
    (target, drinksText) => `Witches cauldron! Everyone adds to a glass/cup. You down it. If you fail, 2 shots. If you succeed, the group does 2 shots.`,
    (target, drinksText) => `Do ${drinksText} for every person not in costume.`,
    (target, drinksText) => `Do ${drinksText} for every person not in costume. They also do it.`,
    (target, drinksText) => `Do ${drinksText} for every person in costume.`,
    (target, drinksText) => `Do ${drinksText} for every person in costume. They also do it.`,
    (target, drinksText) => `Modify your costume so it's now the sexy version of it.`,
    (target, drinksText) => `Hope you picked a good costume. Stay in character for 2 turns.`,
    (target, drinksText) => `Hope you picked a good costume. Stay in character for 3 turns.`,
    (target, drinksText) => `Hope you picked a good costume. Stay in character for 4 turns.`,
    (target, drinksText) => `Hope you picked a good costume. Stay in character for the rest of the game.`,
];

const truths = [
    (target, drinksText) => `Who do you think has the best halloween costume?`,
    (target, drinksText) => `Who do you think has the worst halloween costume?`,
    (target, drinksText) => `Between the players on your left and right, who do you think has the best halloween costume?`,
    (target, drinksText) => `Are you genuinely scared of any halloween monsters?`,
    (target, drinksText) => `Which scary movie has everyone else loved that you hated?`,
    (target, drinksText) => `Ever had your trick or treat sweets bumped?`,
    (target, drinksText) => `Ever had bumped someones trick or treat sweets?`,
    (target, drinksText) => `Tell us about your best ever halloween costume.`,
    (target, drinksText) => `Do you believe in ghosts?`,
    (target, drinksText) => `Have you ever had a paranormal experience?`,
];

export default {
    id: 'goffChristmas',
    title: 'Goff Christmas',
    description: 'It\'s spoopy season, baby',
    dares,
    truths,
};
