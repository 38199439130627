import React from 'react';
import PropTypes from 'prop-types';
import PlayerPill from './PlayerPill'

import {
    PlayersContainer,
    Break,
} from './styles';

import {
    InputLabel,
} from '../../styles';

const PlayersList = ({ players, removePlayer }) => {
    return (
        <PlayersContainer>
            <InputLabel>Current Players:</InputLabel>
            <div>
                {players.map(player =>
                     <PlayerPill name={player.name} id={player.id} key={player.id} removePlayer={removePlayer} />
                )}
                <Break />
            </div>
        </PlayersContainer>
    );
}

PlayersList.propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    removePlayer: PropTypes.func.isRequired,
}

export default PlayersList;
