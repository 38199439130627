import styled from 'styled-components';
import { Link } from 'react-router-dom';

const GalleryContainer = styled.div`
    h1 {
        color: ${props => props.theme.colors.secondary};
    }
`;

const GoHomeLink = styled.div`
    margin-top: 5vw;
    display: flex;
    justify-content: center;

    a {
        color: ${props => props.theme.colors.secondary};
        font-weight: bold;
    }
`;

const ImagePreviewContainer = styled.div`
    width: 18.9%;
    height: 400px;
    position: relative;
    display: flex;
    padding: 0.5vw;
`;

const ImagePreview = styled.div`
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    background-color: ${props => props.theme.colors.white};

    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

const PackContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
`;

const PageContainer = styled.div`
    padding: 1vw;
`;

const ViewButton = styled(Link)`
    position: absolute;
    background-color: transparent;
    border: none;
    padding: 1vw;
    cursor: zoom-in;

    &:focus {
        outline: none;
    }
`;

export {
    GalleryContainer,
    GoHomeLink,
    ImagePreview,
    ImagePreviewContainer,
    ImagesContainer,
    PackContainer,
    PageContainer,
    ViewButton,
}
