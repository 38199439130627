import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    AddPlayerButton,
    AddPlayerContainer,
    AddPlayerInput,
} from './styles';

import {
    InputLabel,
} from '../../styles';

const AddPlayer = ({ addPlayer }) => {
    const [newPlayer, setNewPlayer] = useState('');

    const submitNewPlayer = () => {
        if (newPlayer && newPlayer !== '') {
            addPlayer(newPlayer);
            setNewPlayer('');
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitNewPlayer();
        }
    }

    return (
        <AddPlayerContainer>
            <InputLabel>Add Player:</InputLabel>
            <AddPlayerInput
                value={newPlayer}
                onChange={(e) => setNewPlayer(e.target.value)}
                onKeyDown={handleKeyDown}
                maxLength="20"
            />
            <AddPlayerButton disabled={!newPlayer || newPlayer === ''} onClick={submitNewPlayer}>+</AddPlayerButton>
        </AddPlayerContainer>
    );
}

AddPlayer.propTypes = {
    addPlayer: PropTypes.func.isRequired,
}

export default AddPlayer;
