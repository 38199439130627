import React from 'react';
import PropTypes from 'prop-types';
import Player from './Player';

import {
    PlayersSection,
} from './styles';

const Players = ({ players, activePlayer }) => {
    return (
        <PlayersSection>
            {players.map(player => <Player
                key={player.id}
                isActive={activePlayer && player.id === activePlayer.id}
                player={player}
            />)}
        </PlayersSection>
    )
}

Players.propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
    })),
    activePlayer: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
}

export default Players;