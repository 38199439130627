import React from 'react';
import PropTypes from 'prop-types';

import {
    PlayerPill,
} from './styles';

const Player = ({ player, isActive }) => {
    return (
        <PlayerPill isActive={isActive}>
            <span>{player.name}</span>
        </PlayerPill>
    )
}

Player.propTypes = {
    player: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool,
}

export default Player;
