import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getRoomRoute } from '../../../../../room/routes';
import {
    Modal,
 } from '../../../../../../components';

import {
    DeleteButton,
    GameListButton,
    GamesListContainer,
    GameListUl,
    GameListLi,
    ModalButton,
    ModalPanel,
    ModalTitle,
    ModalControls,
    NoGames,
    NoGamesHeading,
} from './styles';

const GameList = () => {
    const games = localStorage.getItem('games');
    const [prevGames, setPrevGames] = useState(games ? JSON.parse(games) : false);
    const [prevGamesIds, setPrevGamesIds] = useState(Object.keys(prevGames));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [gameToDelete, setGameToDelete] = useState(false);
    const history = useHistory();

    const GoToGame = (id) => {
        history.push(getRoomRoute(id));
    }

    const deleteGame = (idToRemove) => {
        const newSavedGameIds = prevGamesIds.filter(gameId => gameId !== idToRemove);
        const newSavedGames = {};
        newSavedGameIds.forEach(id => newSavedGames[id] = prevGames[id]);
        setPrevGames(newSavedGames);
        setPrevGamesIds(newSavedGameIds);
        localStorage.setItem('games', JSON.stringify(newSavedGames));
    }

    const openDeleteModal = (gameId) => {
        setShowDeleteModal(true);
        setGameToDelete(gameId)
    }

    const confirm = () => {
        deleteGame(gameToDelete);
        setShowDeleteModal(false);
    };

    const closeModal = () => setShowDeleteModal(false)

    if (!prevGames) return (
        <NoGames>
            <NoGamesHeading>No previous games!</NoGamesHeading>
        </NoGames>
    );

    return (
        <GamesListContainer>
            <GameListUl>
                {prevGamesIds.map(gameId => (
                    <GameListLi key={gameId} >
                        <GameListButton onClick={() => GoToGame(gameId)}>{prevGames[gameId].name}</GameListButton>
                        <DeleteButton onClick={() => openDeleteModal(gameId)}>Delete</DeleteButton>
                    </GameListLi>
                ))}
                {showDeleteModal && (
                    <Modal title="Delete game?" closeModal={closeModal}>
                        <ModalPanel>
                            <ModalTitle>
                                <h1>Delete the game: {gameToDelete.name}?</h1>
                            </ModalTitle>
                            <ModalControls>
                                <ModalButton onClick={() => confirm()}>Yes</ModalButton>
                                <ModalButton onClick={closeModal}>No</ModalButton>
                            </ModalControls>
                        </ModalPanel>
                    </Modal>
                )}
            </GameListUl>
        </GamesListContainer>
    );
}

export default GameList;
