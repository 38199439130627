import styled from 'styled-components';
import { Button } from '../../../../../../components';

const DeleteButton = styled(Button)`
    font-size: 1vw;
    border-radius: 0;
`;

const GamesListContainer = styled.div`
    margin-bottom: 5vh;
`;

const GameListButton = styled.div`
    color: ${props => props.theme.colors.secondary};
    line-height: 2vw;
    text-decoration: none;
    cursor: pointer;
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.5vw;
`;

const GameListUl = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const GameListLi = styled.li`
    background-color: ${props => props.theme.colors.primary};
    margin-bottom: 1vw;
    height: 2vw;
    font-size: 1vw;
    display: flex;
    border-radius: 5px;
`;

const ModalPanel = styled.div`
    width: 50vw;
    height: 50vh;
    position: absolute;
    top: 25vh;
    left: 25vw;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;

    h1 {
        color: ${props => props.theme.colors.primary};
    }
`;

const ModalButton = styled(Button)`
    flex: 1;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    line-height: 2vw;
    margin: 0.5vw;
    font-size: 1.5vw;
`;

const ModalTitle = styled.div`
    display: flex;
    margin-bottom: auto;
    justify-content: center;
`;

const ModalControls = styled.div`
    display: flex;
`;


const NoGames = styled.div`
    display: flex;
`;

const NoGamesHeading = styled.span`
    width: 100%;
    text-align: center;
    font-size: 2vw;
`;

export {
    DeleteButton,
    GameListButton,
    GamesListContainer,
    GameListUl,
    GameListLi,
    ModalButton,
    ModalPanel,
    ModalTitle,
    ModalControls,
    NoGames,
    NoGamesHeading,
}
