import styled from 'styled-components';

const SessionCharacterPackContainer = styled.div`
    margin-bottom: 1vh;
`;

const SessionCharacterPackInput = styled.input`
    margin-right: 5%;
`;

const StyledLi = styled.li`
    list-style-type: none;
    padding-bottom: 0.25vw;
`;

export {
    SessionCharacterPackContainer,
    SessionCharacterPackInput,
    StyledLi,
}
