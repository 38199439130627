import React from 'react';
import { Route } from 'react-router-dom';
import About from '.';

const route = [
    <Route
        path="/about"
        exact
        component={About}
        key="/about"
    />,
];

export default route;
