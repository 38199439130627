const dares= [
    (target, drinksText) => `Take a sip of the person to your lefts drink.`,
    (target, drinksText) => `Take a sip of the person to your rights drink.`,
    (target, drinksText) => `Lick your elbow. Or someone elses.`,
    (target, drinksText) => `Phone your mum or dad and ask them for $250 to buy drugs. You can choose who you think would be better for this one.`,
    (target, drinksText) => `Take a single sip from each players drink.`,
    (target, drinksText) => `Sing all of Sk8er Boi by Avril Lavigne - alone.`,
    (target, drinksText) => `Let another player style your hair. You can choose who.`,
    (target, drinksText) => `Without saying anything, leave a group chat you would normally be active in. Preferably not one for this group...`,
    (target, drinksText) => `Choose someone to take your next turn. Take a break. You've earned it, buddy!`,
    (target, drinksText) => `Choose someone to do ${drinksText}. Time to get revenge for something?`,
    (target, drinksText) => `Let the person to your left have a sip of your drink. But you have to hold the cup.`,
    (target, drinksText) => `Time to get changed! Into the same outfit. But everything should be inside out and backwards. Leave it like this for three turns.`,
    (target, drinksText) => `Name the planets in order from the sun. If you get it, everyone else does ${drinksText}. If you don't, you do it for every mistake. Pluto fucking counts.`,
    (target, drinksText) => `Choose someone to do ${drinksText}. Lets hope they don't hold a grudge.`,
    (target, drinksText) => `Choose two people. They swap drinks.`,
    (target, drinksText) => `Take a picture of your big toe and post it to social media. No context allowed. Public privacy settings!`,
    (target, drinksText) => `Imitate another player. If no one can guess who it is, do ${drinksText}. If they can, your target does.`,
    (target, drinksText) => `Turn your phone off for the rest of the game. You've been on it enough anyway.`,
    (target, drinksText) => `High five someone!`,
    (target, drinksText) => `Low five someone!`,
    (target, drinksText) => `Its been a long night. You're probably tired. Until the game ends, no more walking. Crawling, sliding, being carried are all ok.`,
    (target, drinksText) => `Close your eyes until your next turn. That's not so bad, right?`,
    (target, drinksText) => `Did you join a biker gang? Until your next turn, ride your invisible motorcycle whenever you need to go anywhere. Remember, they still make a lot of noise idling.`,
    (target, drinksText) => `Search your own name on facebook. Add the first 5 people.`,
    (target, drinksText) => `Bad luck - do a shot. Something strong though - more than 30%.`,
    (target, drinksText) => `If you're wearing make-up, wash it off. If not, let someone in the group put it on you.`,
    (target, drinksText) => `Tell a joke. Anyone who laughs does ${drinksText}. If no one does, you do it.`,
    (target, drinksText) => `Someone start a timer. Now, pretend to be a food. No not eat it, pretend to be it. If no one can guess it within 60 seconds, you do ${drinksText}. If they can, everyone else does.`,
];

const truths = [
    (target, drinksText) => `Have you ever wet the bed as an adult?`,
    (target, drinksText) => `What's the biggest lie you've ever told?`,
    (target, drinksText) => `What is something you just cannot figure out how to do that everyone else seems to find easy?`,
    (target, drinksText) => `Name a band you love that you know will get you laughed out the room?`,
    (target, drinksText) => `If you could kill someone you know and get away with it, would you? Who?`,
    (target, drinksText) => `You're a stripper. What song plays during your show?`,
    (target, drinksText) => `What are you most scared of?`,
    (target, drinksText) => `What's the worst thing you've ever done?`,
    (target, drinksText) => `Tell us about the last lie you told.`,
    (target, drinksText) => `Have you ever proposed to someone?`,
    (target, drinksText) => `Choose a song to be the only one you ever get to listen to again.`,
    (target, drinksText) => `You suddenly gain the power to become invisible at will. What do you do first?`,
    (target, drinksText) => `What are you most self-conscious about?`,
    (target, drinksText) => `What is the most expensive thing you have stolen?`,
];

export default {
    id: 'saltAndPepper',
    title: 'Salt & Pepper',
    description: 'You like to live life on the vanilla side',
    dares,
    truths,
};
