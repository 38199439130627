import styled from 'styled-components';
import { Button } from '../../../../../../../components';

const Pill = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    width: 27.5%;
    margin: 2.25%;
    float: left;
    font-size: 0.75vw;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    div {
        overflow: hidden;
        width: 80%;
        text-overflow: ellipsis;
    }

    @media (max-width: 768px) {
        font-size: 2vw;
    }
`;

const PillButton = styled(Button)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    width: 20%;
    position: absolute;
    top: 0;
    bottom: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    right: 0;
    font-size: 0.5vw;
`;

export {
    Pill,
    PillButton,
}
