import styled from 'styled-components';
import { Button } from '../../../../../../../../components';

const CardPackButton = styled(Button)`
    font-size: 1.5vw;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    width: 1.5vw;

    @media (max-width: 768px) {
        width: 3vw;
    }
`;

const CardDisplay = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    font-size: 1.5vw;
    line-height: 2.5vw;
    margin-bottom: 0.25vw;
    padding-left: 0.25vw;
    padding-right: 0.25vw;
    color: ${props => props.theme.colors.primary};
`;

const CardPacksContainer = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding-left: 0.25vw;
    display: flex;
    flex-grow: 1;
    padding-right: 0.25vw;
    justify-content: flex-end;
    height: 2.5vw;
    line-height: 2.5vw;
    cursor: pointer;
`;

const CardPacksLabel = styled.span`
    flex-grow: 1;
    font-size: 1vw;
`;

const ModalPanel = styled.div`
    padding-top: 0.5vw;
    width: 75vw;
    height: 75vh;
    position: absolute;
    top: 12.5vh;
    left: 12.5vw;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;
`;

const ModalCards = styled.div`
    overflow-y: scroll;
    padding: 1vw;

    h1,
    h2,
    span {
        color: ${props => props.theme.colors.primary};
        font-size: 1.5vw;
    }
`;

const ModalControls = styled.div`
    display: flex;
    flex-shrink: 0;
`;

const ModalButton = styled(Button)`
    flex: 1;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    line-height: 2vw;
    margin: 0.5vw;
    font-size: 1.5vw;
`;

const SelectedSpan = styled.span`
    padding-right: 0.5vw;
    padding-left: 0.5vw;

    @media (max-width: 768px) {
        font-size: 2vw;
        width: 2vw;
        text-align: center;
    }
`;

export {
    CardDisplay,
    CardPackButton,
    CardPacksContainer,
    CardPacksLabel,
    ModalCards,
    ModalButton,
    ModalControls,
    ModalPanel,
    SelectedSpan,
}
