import styled from 'styled-components';
import { Button } from '../../components';

const IconAndText = styled.div`
    display: flex;
    justify-content: center;
`;

const HomePage = styled.div`
    display: flex;
`;

const HomeContainer = styled.div`
    width: 50%;
    margin-left: 25%;

    h1 {
        text-align: center;
        font-size: 3vw;
        color: ${props => props.theme.colors.secondary};
        margin-bottom: 0;
    }

    h2 {
        text-align: center;
        font-size: 1.5vw;
        color: ${props => props.theme.colors.secondary};
        margin-top: 0;
    }

    @media (max-width: 768px) {
        width: 90%;
        margin-left: 5%;
    }
`;

const MenuButton = styled(Button)`
    width: 100%;
    margin-top: 5vh;

    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
        margin-right: 1vw;
        margin-top: 0.25vw;
    }
`;

const SubMenuButton = styled(Button)`
    width: ${props => props.width ? props.width : '49%'};
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    height: 10vh;
    font-size: 2.5vw;
    line-height: 2.5vw;
`;

const SubMenuContainer = styled.div`
    display: inline-block;
    width: 50%;
    margin-top: 5vh;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 5vh;
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    
    h1 {
        color: ${props => props.theme.colors.primary};
        width: 50%;
        padding-left: 25%;
        text-align: center;
        padding-right: 25%;
    }

    @media (max-width: 768px) {
        width: 75%;
        padding-left: 12.5%;
        padding-right: 12.5%;
    }
`;

export {
    IconAndText,
    HomePage,
    HomeContainer,
    MenuButton,
    SubMenuButton,
    SubMenuContainer,
}
