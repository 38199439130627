import styled from 'styled-components';

const PlayersContainer = styled.div`
    margin-bottom: 1vh;
`;

const Break = styled.br`
    clear: both;
`;

export {
    Break,
    PlayersContainer,
}
