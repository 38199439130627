import styled from 'styled-components';

const SessionCharacterPackContainer = styled.div`
    margin-bottom: 1vh;
`;

const SessionCharacterPackInput = styled.input`
    margin-right: 5%;
`;

const StyledLi = styled.li`
    list-style-type: none;

    label {
        font-size: 1vw;
    }

    @media (max-width: 768px) {
        label {
            font-size: 2vw;
        }
    }
`;

export {
    SessionCharacterPackContainer,
    SessionCharacterPackInput,
    StyledLi,
}
