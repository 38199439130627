import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';

const Modal = ({ closeModal, children, title }) => {
    const getApplicationNode = () =>document.getElementById('application');

    return (
        <AriaModal
            titleText={title}
            onExit={closeModal}
            getApplicationNode={getApplicationNode}
        >
            {children}
        </AriaModal>
    )
}

Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
}

export default Modal;
