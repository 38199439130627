const dares = [
    (target, drinksText) => `Do a shot of the Boukha L'Oasis. 2 normal shots if you refuse.`,
    (target, drinksText) => `Do a shot of the Boukha L'Oasis.`,
    (target, drinksText) => `Choose someone to do a shot of the Boukha L'Oasis.`,
    (target, drinksText) => `Choose someone to do a shot of the Boukha L'Oasis. They get 2 normal shots if they refuse.`,
    (target, drinksText) => `You and ${target} both do a shot of the Boukha L'Oasis.`,
    (target, drinksText) => `You and ${target} both do a shot of the Boukha L'Oasis.`,
    (target, drinksText) => `Escape the room. The last person to touch your leg does a shot of the Boukha L'Oasis.`,
    (target, drinksText) => `Add a shot of the Boukha L'Oasis to your drink.`,
];

const truths = [
    (target, drinksText) => `Who would you murder to avoid doing a shot of the Boukha L'Oasis?`,
    (target, drinksText) => `Would you volunteer to do a shot of the Boukha L'Oasis instead of ${target}?`,
];

export default {
    id: 'theDanniiSpecial',
    title: 'The Dannii Special',
    description: 'Dannii wants to share the joy of Tunisian spirit with you. Requires Boukha L\'Oasis to play',
    dares,
    truths,
};
