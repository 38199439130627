import styled from 'styled-components';

const SessionNameContainer = styled.div`
    margin-bottom: 1vh;
`;

const SessionNameInput = styled.input`
    width: 60%;
    margin-left: 5%;
    height: 1vw;
    font-size: 1vw;
    vertical-align: middle;
`;

export {
    SessionNameContainer,
    SessionNameInput,
}
