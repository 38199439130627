import styled from 'styled-components';
import { Button } from '../../../../../../components';

const AddPlayerContainer = styled.div`
    margin-bottom: 1vh;
`;

const AddPlayerInput = styled.input`
    width: 50%;
    margin-left: 5%;
    height: 1vw;
    font-size: 1vw;
`;

const AddPlayerButton = styled(Button)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    font-size: 1vw;
    width: 2vw;
`;

export {
    AddPlayerContainer,
    AddPlayerInput,
    AddPlayerButton,
}
