import React from 'react';
import PropTypes from 'prop-types';

import {
    Pill,
    PillButton,
} from './styles';

const PlayerPill = ({ id, name, removePlayer }) => {
    return (<Pill>
        <div>{name}</div>
        <PillButton onClick={() => removePlayer(id)}>x</PillButton>
    </Pill>);
};

PlayerPill.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    removePlayer: PropTypes.func.isRequired
}

export default PlayerPill;
