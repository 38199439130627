import styled, { createGlobalStyle } from 'styled-components';
import {
    Button
} from '../../components/';

const ActionControls = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
`;

const ActionButton = styled(Button)`
    flex: 1;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    font-size: 2.5em;
    margin: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin-right: 1vw;
    }

    @media (max-width: 768px) {
        font-size: 1.5em;

        div {
            display: none;
        }
    }

    &:disabled {
        background-color: ${props => props.theme.colors.disabled};
        cursor: default;
    }
`;

const CustomBody = createGlobalStyle`
    body {
        height: 100vh;
        margin: 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-image: linear-gradient(135deg, ${props => props.theme.colors.primaryLight} 0%, ${props => props.theme.colors.primary} 100%);
    }
`;

const FullscreenButton = styled(Button)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    margin: 0.5vw;
    height: 2vw;
    width: 2vw;

    div {
        font-size: 1vw;
        margin-top: 0.25vw;
    }
`;

const RoomContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

RoomContainer.displayName = 'RoomContainer';

const BottomSection = styled.div`
    display: flex;
    margin: 1%;
    flex-direction: row;
    flex: 1;
    margin-bottom: 2vw;

    @media (max-width: 768px) {
        margin-bottom: 3vw;
    }
`;

const BottomPanel = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    width: 49%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {
        margin-left: 1%;
    }

    &:nth-child(odd) {
        margin-right: 1%;
    }
`;

const TopTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 5vw;
`;

const TopSection = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};

    margin: 1% 1% 0;
    display: inline-block;
    padding-bottom: 1%;

    h1 {
        width: 99%;
        margin-left: 1%;
        font-size: 2vw;
    }
`;


const ModalPanel = styled.div`
    background-color: ${props => props.theme.colors.secondary};
    width: 50vw;
    height: 90vh;
    position: absolute;
    top: 5vh;
    left: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const ModalTitle = styled.div`
    align-self: flex-start;
    width: 100%;

    h1 {
        color: ${props => props.theme.colors.primary};
        text-align: center;
    }
`;

const ModalContent = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;

    img {
        height: 60vh;
        align-self: flex-end;
    }
`;

const ModalControls = styled.div`
    width: 100%;
    justify-content: center;
`;

const ModalButton = styled(Button)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0.5vw;
`;

const RulesButton = styled(Button)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
    margin: 0.5vw;
    line-height: 2vw;
    height: 2vw;
    font-size: 1.5vw;
    padding-right: 1vw;
    padding-left: 1vw;
`;

export {
    ActionButton,
    ActionControls,
    BottomSection,
    BottomPanel,
    CustomBody,
    FullscreenButton,
    RoomContainer,
    TopTitleRow,
    TopSection,
    ModalPanel,
    ModalTitle,
    ModalContent,
    ModalControls,
    ModalButton,
    RulesButton,
}
