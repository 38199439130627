import ShitebagImage from './shitebag.png';
import SpinnerImage from './spinner.png';
import treatImg from './treat.png';
import trickImg from './trick.png';
import shotImg from './shot.png';
import treat2Img from './treat2.png';
import trick2Img from './trick2.png';
import shot2Img from './shot2.png';
import treat3Img from './treat3.png';
import trick3Img from './trick3.png';
import shot3Img from './shot3.png';
import treat4Img from './treat4.png';
import trick4Img from './trick4.png';
import backstabImg from './backstab.png';
import backstab2Img from './backstab2.png';
import backstab3Img from './backstab3.png';
import treat5Img from './treat5.png';
import trick5Img from './trick5.png';

export default {
    name: 'Goff Christmas',
    Shitebag: ShitebagImage,
    SpinnerImage: SpinnerImage,
    Treat: [
        treatImg,
        treat2Img,
        treat3Img,
        treat4Img,
        treat5Img,
    ],
    Trick: [
        trickImg,
        trick2Img,
        trick3Img,
        trick4Img,
        trick5Img,
    ],
    Shot: [
        shotImg,
        shot2Img,
        shot3Img,
    ],
    Backstab: [
        backstabImg,
        backstab2Img,
        backstab3Img,
    ]
}