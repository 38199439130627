import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import LoadIcon from '../../../../../img/menu.svg';
import {
    Icon,
 } from '../../../../components';

import {
    GameList
} from './components';

import {
    IconAndText,
    MenuButton,
    SubMenuButton,
    SubMenuContainer,
} from '../../styles';

const LoadSession = ({ theme }) => {
    const [showLoadGame, setShowLoadGame] = useState(false);

    return (
        <React.Fragment>
            {!showLoadGame && (
                <MenuButton onClick={() => setShowLoadGame(true)}>
                    <IconAndText>
                        <Icon
                            name="create"
                            path={LoadIcon}
                            size="3.5vw"
                            color={theme.colors.primary}
                        />
                        Load Game
                    </IconAndText>
                </MenuButton>
            )}
            {showLoadGame && (
                <SubMenuContainer>
                    <h1>Load Game</h1>
                    <GameList />
                    <SubMenuButton width="100%" onClick={() => setShowLoadGame(false)}>Cancel</SubMenuButton>
                </SubMenuContainer>
            )}
        </React.Fragment>
    )
}

LoadSession.propTypes = {
    theme: PropTypes.shape({}).isRequired,
}

export default withTheme(LoadSession);
