const dares = [
    (target, drinksText) => `Wildcard! Dare anyone you want anything you want`,
    (target, drinksText) => `Wildcard! Dare ${target} anything you want`,
];

const truths = [
    (target, drinksText) => `Wildcard! Ask anyone you want anything you want`,
    (target, drinksText) => `Wildcard! Ask ${target} anything you want`,
];

export default {
    id: 'beTheWildcard',
    title: 'Be The Wildcard',
    description: 'Always wanted to be the wildcard? Now you can!',
    dares,
    truths,
};
