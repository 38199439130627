import {lighten } from 'polished';

export default {
    colors: {
        primary: '#2F3C76',
        primaryLight: '#4556A7',
        primaryLightest: lighten(0.1, '#4556A7'),
        secondary: '#fff',
        active: '#22ff52',
        activeLight: lighten(0.1, '#22ff52'),
        disabled: '#c1cbd8',
        white: '#ffffff',
        red: '#c74545',
    },
}
