const dares = [
    (target, drinksText) => `Show the group the last text you sent.`,
    (target, drinksText) => `It's ${target}s lucky day!. They get to write something on your arm with a permanent marker.`,
    (target, drinksText) => `Do an impression of ${target} trying to chat someone up in a bar.`,
    (target, drinksText) => `Find the cheapest sex toy you can find on amazon and buy it. Have it sent to an ex.`,
    (target, drinksText) => `Confess your love to someone. Choose between the players on your left and right. Make it good.`,
    (target, drinksText) => `Admit how much you hate someone. Choose between the players on your left and right. Will this take much acting?`,
    (target, drinksText) => `Choose two players. They slow dance together to a song of your choosing.`,
    (target, drinksText) => `Write your phone password/code on a piece of paper. Hide it somewhere in the flat/house. Try not to forget about it.`,
    (target, drinksText) => `Hope you like whoevers on the right - hold their hand until your next turn.`,
    (target, drinksText) => `Hope you like whoevers on the right - hold their hand for three turns.`,
    (target, drinksText) => `Phone someone you haven't talked to in months. Give out ${drinksText} if its an ex.`,
    (target, drinksText) => `Choose the person you think is hottest, and the person you think is ugliest. Don't say which is which, but they do ${drinksText} each.`,
    (target, drinksText) => `Pass your ID around. Let's trust the group to give it back at the end.`,
    (target, drinksText) => `${target} gets to choose someone on your facebook friends list of the opposite sex. Like a profile picture of theirs from at least 2 years ago. Bonus points if it's a beach photo!`,
    (target, drinksText) => `Show the group the last thing you searched on your phone.`,
    (target, drinksText) => `Choose a body part. Last person to touch it (on you) does ${drinksText}.`,
    (target, drinksText) => `Give a massage to whoever raises their hand first.`,
    (target, drinksText) => `Swap outfit with ${target}. Swap back when the group deems fit.`,
    (target, drinksText) => `Swap outfit with ${target}. Swap back after the game ends.`,
    (target, drinksText) => `You aren't allowed to make a sound until your next turn. If you do, its ${drinksText}. And then ${drinksText} again. The other players are welcome to interfere...`,
    (target, drinksText) => `Your mission is to appropriate ${target}s phone. If you have it by the end of your 3rd turn from now, you choose their punishment. If not, do ${drinksText}.`,
    (target, drinksText) => `Undo ${target}s zipper with your teeth. No zipper? Go for buttons. No buttons? Do ${drinksText}.`,
    (target, drinksText) => `Kiss ${target} on the lips.`,
    (target, drinksText) => `Kiss the last two people to do shots on the lips. Whoever was worse does ${drinksText}.`,
    (target, drinksText) => `Spoon ${target} until your next turn. If you separate for any reason, you both do ${drinksText}.`,
    (target, drinksText) => `You could use a new profile photo. Let ${target} take it, as well as decide the pose and location. To facebook it goes!`,
    (target, drinksText) => `Unlock your phone and hand it to ${target}. You get it back in 60 seconds.`,
    (target, drinksText) => `Freestyle time - dare ${target} anything. All normal rules apply to them now.`,
    (target, drinksText) => `Until the next turn, pretend you're groping invisible boobs. Even if something real finds it's way into your grip...`,
];

const truths = [
    (target, drinksText) => `Which cartoon character is your biggest crush?`,
    (target, drinksText) => `Who in this room do you hate most?`,
    (target, drinksText) => `Have you ever went on a date with someone just to get free food/drink/travel out of it?`,
    (target, drinksText) => `Name someone in the room you know is into some weird shit.`,
    (target, drinksText) => `If you had to have sex with one animal, what would it be and why?`,
    (target, drinksText) => `Tell us all something you don't want us to know.`,
    (target, drinksText) => `Your mother and girlfriend have switched bodies. The only way to switch them back is to have sex with one of them. Do you? Which one?`,
    (target, drinksText) => `What's your most embarrassing school story?`,
    (target, drinksText) => `Oh no! A snake just bit the penis of the first guy to your left! The only way to save him is to suck it out. Would you?`,
    (target, drinksText) => `Tell us about ${target}: what's their most attractive physical feature?`,
    (target, drinksText) => `Let's hear about ${target}: what's their best quality?`,
    (target, drinksText) => `Who in the room do you think is least capable at keeping a pet alive?`,
    (target, drinksText) => `Who here needs a new haircut the most?`,
    (target, drinksText) => `Pick someone in the group you'd happily trade lives with?`,
    (target, drinksText) => `Name someone you pretend to like but actually don't.`,
    (target, drinksText) => `Tell us all something that no one knows about you.`,
    (target, drinksText) => `Say something nice about each person in the room.`,
    (target, drinksText) => `Feeling mean? Look at ${target}. Tell them how to improve their fashion sense`,
    (target, drinksText) => `What's the furthest you've ever went for a guy/girl you had a crush on?`,
    (target, drinksText) => `${target} is getting married. What objection do you shout out to ruin the whole thing?`,
    (target, drinksText) => `You're about to be stranded on a desert island for a year. Pick someone here to be stranded with.`,
    (target, drinksText) => `Uh oh, you're in a plane for some reason. Even more improbable, it suddenly can't carry you all. You just have to choose one person to push out, and there's no parachute. Who's it gonna be?`,
    (target, drinksText) => `Who's the worst dancer in the room?`,
    (target, drinksText) => `You have a child that's around the groups age. Are you ok with them dating ${target}?`,
    (target, drinksText) => `Say something nice about each person in the room, except one person.`,
    (target, drinksText) => `How do you think ${target} really feels about you?`,
    (target, drinksText) => `Freestyle time - ask ${target} anything. All normal rules apply to them now.`,
];

export default {
    id: 'allFriendsHere',
    title: 'We\'re all Friends Here',
    description: 'These ones really depend on how much you trust the people around you...',
    dares,
    truths,
};
