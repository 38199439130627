import React from 'react';
import { Route } from 'react-router-dom';
import Gallery from '.';

const ROUTE_URL = 'gallery';

export const getGalleryRoute = () => {
    return `/${ROUTE_URL}`;
}

const route = (
    <Route
        path={`/${ROUTE_URL}`}
        exact
        component={Gallery}
        key={`/${ROUTE_URL}`}
    />
);

export default route;
