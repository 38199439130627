export default [
    '1 sip',
    '1 sip',
    '1 sip',
    '2 sips',
    '2 sips',
    '2 sips',
    '2 sips',
    '2 sips',
    '2 sips',
    '3 sips',
    '3 sips',
    '3 sips',
    '3 sips',
    '4 sips',
    '4 sips',
    '4 sips',
    '5 sips',
    '5 sips',
    'a shot',
]
