export default {
    id: 'classic',
    label: 'Classic',
    segments: [
        "Shot",
        "Truth",
        "Dare",
        "Backstab",
        "Shot",
        "Truth",
        "Dare",
        "Backstab",
        "Truth",
        "Dare",
    ],
};
