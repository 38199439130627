const dares = [
    (target, drinksText) => `Drop some ice cubes into your underwear. They must remain until they melt.`,
    (target, drinksText) => `Do 10 push-ups.`,
    (target, drinksText) => `The player across from you is your new best friend. If by friend, we mean attacker. They get to slap you in the face.`,
    (target, drinksText) => `Do 1 push-up while the tallest person sits on your back.`,
    (target, drinksText) => `Give the person on ${target}s left a wet willy.`,
    (target, drinksText) => `Choose a guy in the room to do 10 sit-ups.`,
    (target, drinksText) => `Choose a girl in the room to do 10 sit-ups.`,
    (target, drinksText) => `Titty twist someone. If someones not in the room right now, choose them.`,
    (target, drinksText) => `Carry ${target} to the kitchen and back.`,
    (target, drinksText) => `Do a 60 second plank. Failure means ${drinksText}.`,
    (target, drinksText) => `Arm wrestle ${target}. Loser gets ${drinksText}.`,
    (target, drinksText) => `Touch your toes without bending your legs. If you try but fail, do ${drinksText}.`,
    (target, drinksText) => `Do 25 squats. Work those glutes!`,
    (target, drinksText) => `Go to the window and flash the street.`,
];

const truths = [
    (target, drinksText) => `Would you rather have penis-length nipples or a nipple-length penis?`,
    (target, drinksText) => `If you're a guy, tell us your penis length. If you're a woman, your cup size.`,
    (target, drinksText) => `Have you ever won a fight?`,
    (target, drinksText) => `Who here do you think couldn't win a fight against anyone else in the group?`,
    (target, drinksText) => `What's the most embarrassing way you've injured yourself?`,
    (target, drinksText) => `If you had to choose, who in the group would you fight?`,
];

export default {
    id: 'letsGetPhysical',
    title: 'Lets Get Physical',
    description: 'The human body truly is amazing',
    dares,
    truths,
};
