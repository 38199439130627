import styled from 'styled-components';

const CreateSessionControls = styled.div`
    display: flex;
`;

const ErrorSpan = styled.span`
    color: ${props => props.theme.colors.red};
`;

const InputLabel = styled.p`
    font-size: 1vw;
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 2vw;
    }
`;

export {
    CreateSessionControls,
    ErrorSpan,
    InputLabel,
}