const dares = [
    (target, drinksText) => `Take off an item of clothing. You can wear it again on your next turn.`,
    (target, drinksText) => `Take off an item of clothing. You can wear it again in three turns.`,
    (target, drinksText) => `Take off an item of clothing. You can wear it again when the group votes to allow it.`,
    (target, drinksText) => `Take off an item of clothing. You can wear it again when someone else takes something off.`,
    (target, drinksText) => `Take off an item of clothing. This one stays off all game.`,
    (target, drinksText) => `Lick ${target}.`,
    (target, drinksText) => `Swap an item of clothing with ${target}. Shoes, socks, accessories don't count this time. Swap back on your next turn.`,
    (target, drinksText) => `Swap an item of clothing with ${target}. Shoes, socks, accessories don't count this time. Swap back when the group votes to allow it.`,
    (target, drinksText) => `Swap an item of clothing with ${target}. Shoes, socks, accessories don't count this time. Stay swapped the rest of the game.`,
    (target, drinksText) => `Choose someone. They remove an item of clothing. You can keep it until they earn it back.`,
    (target, drinksText) => `Choose someone. They remove an item of clothing. You can keep it until their next turn.`,
    (target, drinksText) => `Choose someone. They remove an item of clothing. You can keep it until they finish their drink.`,
    (target, drinksText) => `You'll be sitting on ${target}s lap until your next turn. How romantic!`,
    (target, drinksText) => `You'll be sitting on ${target}s lap until you finish your drink. Is this love?`,
    (target, drinksText) => `Give everyone a kiss on the cheek. Take ${drinksText} for each person that refuses.`,
    (target, drinksText) => `Finish the game in your underwear. Failing this one means 2 shots...`,
    (target, drinksText) => `Who was last to get a new drink? Give them a lap dance.`,
    (target, drinksText) => `Swap your shirt with someone of the opposite sex. ${drinksText} if you leave the room though. Swap back next turn.`,
    (target, drinksText) => `Swap your shirt with someone of the opposite sex. ${drinksText} if you leave the room though. Swap back in four turns.`,
    (target, drinksText) => `Swap your shirt with someone of the opposite sex. ${drinksText} if you leave the room though. Swap back after the game.`,
    (target, drinksText) => `Take off your underwear without leaving the room. You can put it back on whenever you want, but only without leaving the room.`,
    (target, drinksText) => `Show us your favourite sexual position... demonstrate it on the person to your left.`,
    (target, drinksText) => `Show your bum to the group.`,
    (target, drinksText) => `Fake an orgasm.`,
    (target, drinksText) => `Choose a partner. For every item of clothing you take off, so do they. They do ${drinksText} for every refusal.`,
    (target, drinksText) => `Hope you like the people around you - rub the nipples of those to your left and right.`,
    (target, drinksText) => `Show one of your nudes to someone in the group.`,
    (target, drinksText) => `Do you think ${target} is thorough in the shower? Time to find out - nibble their ear.`,
    (target, drinksText) => `Swap your shirt with the closest person of the opposite sex. You can swap back if the group votes to allow it.`,
    (target, drinksText) => `Swap your shirt with the closest person of the opposite sex. You can swap back on your next turn.`,
    (target, drinksText) => `Swap your shirt with the closest person of the opposite sex. You can swap back when the game ends.`,
    (target, drinksText) => `Flash. Now.`,
    (target, drinksText) => `The person to your right seems a little suspicious. Give them a very careful pat down... `,
    (target, drinksText) => `Lets do another lap dance - this time to whoever you think is wearing the sexiest outfit.`,
    (target, drinksText) => `Lets get close to ${target}. Suck their finger seductively.`,
    (target, drinksText) => `Kiss the person to your left. They do the same. Continue until someone says no. First refusal downs their drink.`,
    (target, drinksText) => `Motorboat the person with the drink that's fullest.`,
    (target, drinksText) => `Things seem too vanilla. Let the group tie you up. You'll be freed on your next turn.`,
    (target, drinksText) => `Things seem too vanilla. Let the group tie you up. You'll be freed when the group votes to allow it.`,
    (target, drinksText) => `Kiss the neck of the person to your left. If you give them a hickey, the person to your right does ${drinksText}.`,
    (target, drinksText) => `Do a strip show. For every piece of clothing you take off (not counting shoes, socks or accessories) everyone else does ${drinksText} or also takes something off. Re-dress at the end, if you want...`,
    (target, drinksText) => `Let ${target} spank you.`,
    (target, drinksText) => `Spank ${target}.`,
    (target, drinksText) => `Let ${target} do a shot off your chest.`,
    (target, drinksText) => `Do a shot off ${target}s chest.`,
    (target, drinksText) => `Let ${target} strip your top half. Underwear optional. You can put it back on in two turns.`,
    (target, drinksText) => `Let ${target} strip your top half. Underwear optional. You can put it back on in three turns.`,
    (target, drinksText) => `Let ${target} strip your top half. Underwear optional. You can put it back on when the game ends.`,
    (target, drinksText) => `Let ${target} strip your bottom half. Underwear optional. You can put it back on in two turns.`,
    (target, drinksText) => `Let ${target} strip your bottom half. Underwear optional. You can put it back on in three turns.`,
    (target, drinksText) => `Let ${target} strip your bottom half. Underwear optional. You can put it back on when the game ends.`,
];

const truths = [
    (target, drinksText) => `Most inappropriate place you've had sex?`,
    (target, drinksText) => `Which of the guys do you think has the biggest dick?`,
    (target, drinksText) => `Which two people in the room do you think would be the best for a threesome?`,
    (target, drinksText) => `Have you ever posted nudes online?`,
    (target, drinksText) => `Who's the last person you fantasized about?`,
    (target, drinksText) => `Who in the group would you pay the most money to sleep with?`,
    (target, drinksText) => `Who in the group would you charge the most money to sleep with?`,
    (target, drinksText) => `Who's the second hottest person in the room?`,
    (target, drinksText) => `Would you rather kiss the person on your left or right?`,
    (target, drinksText) => `How many people have you had sex with at the same time?`,
    (target, drinksText) => `How many people would you be willing to have sex with at the same time?`,
    (target, drinksText) => `Have you ever injured yourself during sex?`,
    (target, drinksText) => `Have you ever accepted money/gifts for sex?`,
    (target, drinksText) => `What's the first thing you would do if you woke up in the body of the opposite sex?`,
    (target, drinksText) => `Let's play a game. Marry, fuck, kill - choose from the group.`,
    (target, drinksText) => `Have you ever done anything sexual with a friends parent?`,
    (target, drinksText) => `Who in the room would be the worst person to date?`,
    (target, drinksText) => `What's your sexual guilty pleasure?`,
    (target, drinksText) => `Have you ever tried to take a sexy picture of yourself`,
    (target, drinksText) => `Are you into the idea of fucking while watching porn?`,
    (target, drinksText) => `What's your go-to porn site?`,
    (target, drinksText) => `Would you have a threesome while in a relationship?`,
    (target, drinksText) => `Would you be turned on watching a partner fuck someone else?`,
    (target, drinksText) => `How young would you be ok with for a sexual partner?`,
    (target, drinksText) => `How old would you be ok with for a sexual partner?`,
    (target, drinksText) => `True or false: you have a crush on ${target}. If yes, they do ${drinksText}.`,
    (target, drinksText) => `Do you think ${target} is cute?. If not, they do ${drinksText}.`,
];

export default {
    id: 'goToHornyJail',
    title: 'Go To Horny Jail',
    description: 'Get bonked, you horny fucks',
    dares,
    truths,
};
