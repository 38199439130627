import allFriendsHere from './allFriendsHere';
import beTheWildcard from './beTheWildcard';
import goToHornyJail from './goToHornyJail';
import letsGetPhysical from './letsGetPhysical';
import saltAndPepper from './saltAndPepper';
import theDanniiSpecial from './theDanniiSpecial';
import goffChristmas from './goffChristmas';

export default [
    {
        ...allFriendsHere,
    },
    {
        ...beTheWildcard,
    },
    {
        ...goToHornyJail,
    },
    {
        ...letsGetPhysical,
    },
    {
        ...saltAndPepper,
    },
    {
        ...theDanniiSpecial,
    },
    {
        ...goffChristmas,
    }
]
