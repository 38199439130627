import React from 'react';
import PropTypes from 'prop-types';

import {
    CardPackSelector,
} from './components';

import {
    SessionCharacterPackContainer,
    StyledLi,
} from './styles';

import {
    InputLabel,
} from '../../styles';

const SessionCharacterPack = ({
    sessionCardPacks,
    updateSessionCardPackSelection
}) => {

    return (
        <SessionCharacterPackContainer>
            <InputLabel>Card Packs:</InputLabel>
            <ul>
                {sessionCardPacks.map(pack => (
                    <StyledLi key={pack.id}>
                        <CardPackSelector
                            cardPackDescription={pack.description}
                            cardPackId={pack.id}
                            cardPackTitle={pack.title}
                            selected={pack.selected}
                            setSelected={(newSelection) => updateSessionCardPackSelection(pack.id, newSelection)}
                        />
                    </StyledLi>
                ))}
            </ul>
        </SessionCharacterPackContainer>
    );
}

SessionCharacterPack.propTypes = {
    sessionCardPacks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        selected: PropTypes.number.isRequired,
    })).isRequired,
    updateSessionCardPackSelection: PropTypes.func.isRequired,
}

export default SessionCharacterPack;
