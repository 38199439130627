import React from 'react';
import PropTypes from 'prop-types';

import {
    SessionCharacterPackContainer,
    SessionCharacterPackInput,
    StyledLi,
} from './styles';

import {
    InputLabel,
} from '../../styles';

const SessionCharacterPack = ({ 
    characterPacks,
    setSessionCharacterPack,
    sessionCharacterPack
}) => {
    return (
        <SessionCharacterPackContainer>
            <InputLabel>Character Pack:</InputLabel>
            <ul>
                {characterPacks.map(pack => (
                    <StyledLi key={pack.id}>
                        <SessionCharacterPackInput
                            type="radio"
                            name={pack.id}
                            value={pack.id}
                            checked={sessionCharacterPack === pack.id}
                            onChange={() => setSessionCharacterPack(pack.id)}
                        />
                        <label htmlFor={pack.id}>{pack.label}</label>
                    </StyledLi>
                ))}
            </ul>
        </SessionCharacterPackContainer>
    );
}

SessionCharacterPack.propTypes = {
    characterPacks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    sessionCharacterPack: PropTypes.string.isRequired,
    setSessionCharacterPack: PropTypes.func.isRequired,
}

export default SessionCharacterPack;
