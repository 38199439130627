import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import QuitIcon from '../../../../../img/sad.svg';

import {
    Icon,
    Modal,
 } from '../../../../components';

import {
    StyledQuitButton,
    QuitButtonSection,
    ModalButton,
    ModalPanel,
    ModalTitle,
    ModalControls,
    IconAndText,
} from './styles';

const QuitButton = ({ theme }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const quit = () => {
        setShowModal(false);
        history.push('/home');
    };

    const closeModal = () => setShowModal(false)

    return (
        <QuitButtonSection>
            {showModal && (
                <Modal title="Quit ya prick?" closeModal={closeModal}>
                    <ModalPanel>
                        <ModalTitle>
                            <h1>Quit the game?</h1>
                        </ModalTitle>
                        <ModalControls>
                            <ModalButton onClick={() => quit()}>Yes</ModalButton>
                            <ModalButton onClick={closeModal}>Keep Playing</ModalButton>
                        </ModalControls>
                    </ModalPanel>
                </Modal>
        )}
            <StyledQuitButton onClick={() => setShowModal(true)}>
                <IconAndText>
                    <Icon
                        path={QuitIcon}
                        size="1.5vw"
                        color={theme.colors.secondary}
                    />
                    Quit
                </IconAndText>
            </StyledQuitButton>
        </QuitButtonSection>
    )
}

export default withTheme(QuitButton);