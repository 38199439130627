export default {
    id: 'goffChristmas',
    label: 'Goff Christmas',
    segments: [
        "Shot",
        "Treat",
        "Trick",
        "Backstab",
        "Shot",
        "Treat",
        "Trick",
        "Backstab",
        "Treat",
        "Trick",
    ],
};
