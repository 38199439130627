import styled from 'styled-components';

const PlayersSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 1%;
    margin-right: 1%;
`;


export {
    PlayersSection,
}
