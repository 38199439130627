import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, ThemeProvider } from 'styled-components';

import goffChristmasTheme from './goffChristmas.theme';
import availableSessionCardPacks from '../../cardPacks';
import boards from '../../../data/boards';
import {
    ActionPanel,
    Players,
    QuitButton,
    RulesModal,
    Wheel,
} from './components';

import {
    Icon,
    Modal,
 } from '../../components/';

import images from './images';
import drinksTextData from '../../../data/drinksTexts';

import {
    ActionButton,
    ActionControls,
    BottomSection,
    BottomPanel,
    CustomBody,
    RoomContainer,
    TopTitleRow,
    TopSection,
    ModalPanel,
    ModalTitle,
    ModalContent,
    ModalControls,
    ModalButton,
    RulesButton,
} from './styles';

import {
    WheelImage
} from './components/Wheel/styles';

import SpinIcon from '../../../img/casino.svg';
import RedrawIcon from '../../../img/deck.svg';

const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

const Room = ({ theme, match: { params: { id } } }) => {



    const thisSession = JSON.parse(localStorage.getItem('games'))[id];
    const players = thisSession.players;

    let selectedImagePack = thisSession.characterPack ? thisSession.characterPack : 'goffPack';
    const imagePack = images[selectedImagePack];
    const boardToFind = selectedImagePack === 'goffChristmasPack' ? 'goffChristmas' : 'classic';
    const sessionTheme = selectedImagePack === 'goffChristmasPack' ? goffChristmasTheme : theme;
    
    const boardIndex = boards.findIndex(obj => obj.id === boardToFind)
    const board = boards[boardIndex];

    const initialState = {
        actionImage: null,
        actionPlayer: -1,
        activePlayer: 0,
        currentAction: null,
        currentDaresIndex: -1,
        currentTruthsIndex: -1,
        drinksText: drinksTextData[0], 
        gameTruths: availableSessionCardPacks[0].truths,
        gameDares: availableSessionCardPacks[0].dares,
        targetPlayer: players[1],
    }

    const [state, setState] = useState(initialState);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showRedrawModal, setShowRedrawModal] = useState();
    const [showRules, setShowRules] = useState(true);

    useEffect(() => {
        let truths = [];
        let dares = [];

        if (!thisSession.cardPacks) {
            for (let i = 0; i < availableSessionCardPacks.length; i = i + 1) {
                truths = [...truths, ...availableSessionCardPacks[i].truths];
                dares = [...dares, ...availableSessionCardPacks[i].dares];
            }
        } else {
            thisSession.cardPacks.forEach((pack) => {
                const packToUpdateIndex = availableSessionCardPacks.findIndex((packToFind => packToFind.id === pack.id));
                for (let i = 0; i < pack.selected; i = i + 1) {
                    truths = [...truths, ...availableSessionCardPacks[packToUpdateIndex].truths];
                    dares = [...dares, ...availableSessionCardPacks[packToUpdateIndex].dares];
                }
            });
        }

        setState({
            ...state,
            gameTruths: shuffle(truths),
            gameDares: shuffle(dares)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const progressToNextPlayer = (activePlayer) => {
        const nextPlayerIndex = activePlayer + 1 === players.length ? 0 : activePlayer + 1;
        return nextPlayerIndex;
    }

    const nextActionCard = (action, currentDaresIndex, currentTruthsIndex) => {
        const newIndices = {
            currentDaresIndex,
            currentTruthsIndex
        }
        switch(action) {
            case 'Truth':
            case 'Treat':
                const nextTruthsIndex = currentTruthsIndex + 1 === state.gameTruths.length ? 0 : currentTruthsIndex + 1;
                newIndices.currentTruthsIndex = nextTruthsIndex;
                break;
            case 'Dare':
            case 'Trick':
                const nextDaresIndex = currentDaresIndex + 1 === state.gameDares.length ? 0 : currentDaresIndex + 1;
                newIndices.currentDaresIndex = nextDaresIndex;
                break;
            default:
                break;
        }
        return newIndices;
    }

    const getActionImage = (action) => {
        return (
            <WheelImage image={imagePack[action][Math.floor(Math.random() * imagePack[action].length)]} />
        );
    }

    const getNewTargetPlayer = (activePlayer) => {
        const currentPlayer = players[activePlayer];
        const possibleTargets = players.filter(player => player.id !== currentPlayer.id);
        return possibleTargets[[Math.floor(Math.random() * possibleTargets.length)]];
    }

    const draw = () => {
        const action = board.segments[Math.floor(Math.floor(Math.random() * board.segments.length))];
        setIsSpinning(true);
        setTimeout(() => {
            const newActions = nextActionCard(action, state.currentDaresIndex, state.currentTruthsIndex);
            const newActivePlayer = progressToNextPlayer(state.activePlayer);
            setState({
                ...state,
                actionImage: getActionImage(action),
                actionPlayer: state.activePlayer,
                activePlayer: newActivePlayer,
                currentAction: action,
                currentDaresIndex: newActions.currentDaresIndex,
                currentTruthsIndex: newActions.currentTruthsIndex,
                drinksText: drinksTextData[Math.floor(Math.random() * drinksTextData.length)],
                targetPlayer: getNewTargetPlayer(state.activePlayer)
            });
            
            setIsSpinning(false);
            
        }, 3000)
    }

    const activateRedraw = () => {
        setShowRedrawModal(false);
        redraw();
    }

    const redraw = () => {
        setIsSpinning(true);
        setTimeout(() => {
            const newActions = nextActionCard(state.currentAction, state.currentDaresIndex, state.currentTruthsIndex);

            setState({
                ...state,
                currentDaresIndex: newActions.currentDaresIndex,
                currentTruthsIndex: newActions.currentTruthsIndex,
                drinksText: drinksTextData[Math.floor(Math.random() * drinksTextData.length)],
            });

            setIsSpinning(false);
        }, 3000)
    };

    const isTruthOrDare = () => {
        return (state.currentAction) ? ['truth', 'dare', 'trick', 'treat'].includes(state.currentAction.toLowerCase()) : false;
    }

    return (
        <ThemeProvider theme={sessionTheme}>
            <CustomBody />
            <RoomContainer>
                {showRules && (
                    <RulesModal closeModal={() => setShowRules(false)} />
                )}
                {showRedrawModal && (
                    <Modal closeModal={activateRedraw} title="Shitebag time">
                        <ModalPanel>
                            <ModalTitle>
                                <h1>You shitebag. Do a shot!</h1>
                            </ModalTitle>
                            <ModalContent>
                                <img alt="shitebag" src={imagePack.Shitebag} />
                            </ModalContent>
                            <ModalControls>
                                <ModalButton onClick={activateRedraw}>Sorry</ModalButton>
                            </ModalControls>
                        </ModalPanel>
                    </Modal>
                )}
                <TopSection>
                    <TopTitleRow>
                        <h1>Shitebags! [Game ID: {thisSession.name}]</h1>
                        <RulesButton onClick={() => setShowRules(true)}>Rules</RulesButton>
                        <QuitButton />
                    </TopTitleRow>
                    <div>
                        <Players players={players} activePlayer={players[state.actionPlayer]} />
                    </div>
                </TopSection>
                <BottomSection>
                    <BottomPanel>
                        <ActionPanel
                            player={players[state.actionPlayer]}
                            currentAction={state.currentAction}
                            currentTruthsIndex={state.currentTruthsIndex}
                            truth={state.gameTruths[state.currentTruthsIndex]}
                            dare={state.gameDares[state.currentDaresIndex]}
                            targetPlayer={state.targetPlayer}
                            drinksText={state.drinksText}
                        />
                        <ActionControls>
                            <ActionButton disabled={isSpinning} onClick={draw}>
                                <Icon
                                    path={SpinIcon}
                                    size="0.8em"
                                    color={sessionTheme.colors.secondary}
                                />
                                SPIN!
                            </ActionButton>
                            <ActionButton onClick={() =>setShowRedrawModal(true)} disabled={!isTruthOrDare() || isSpinning}>
                                <Icon
                                    path={RedrawIcon}
                                    size="1.25em"
                                    color={sessionTheme.colors.secondary}
                                />
                                Redraw
                            </ActionButton>
                        </ActionControls>
                    </BottomPanel>
                    <BottomPanel>
                        <Wheel
                            isSpinning={isSpinning}
                            currentAction={state.currentAction}
                            ActionImage={state.actionImage}
                            spinnerImg={imagePack.SpinnerImage}
                        />
                    </BottomPanel>
                </BottomSection>
            </RoomContainer>
        </ThemeProvider>
    )
};

Room.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}

export default withTheme(Room);
