import ShitebagImage from './shitebag.png';
import SpinnerImage from './spinner.png';
import truthImg from './truth.png';
import dareImg from './dare.png';
import shotImg from './shot.png';
import truth2Img from './truth2.png';
import dare2Img from './dare2.png';
import shot2Img from './shot2.png';
import truth3Img from './truth3.png';
import dare3Img from './dare3.png';
import shot3Img from './shot3.png';
import truth4Img from './truth4.png';
import shot4Img from './shot4.png';
import dare4Img from './dare4.png';
import backstabImg from './backstab.png';
import backstab2Img from './backstab2.png';
import truth5Img from './truth5.png';
import shot5Img from './shot5.png';
import dare5Img from './dare5.png';

export default {
    name: 'Goff Gaff',
    Shitebag: ShitebagImage,
    SpinnerImage: SpinnerImage,
    Truth: [
        truthImg,
        truth2Img,
        truth3Img,
        truth4Img,
        truth5Img,
    ],
    Dare: [
        dareImg,
        dare2Img,
        dare3Img,
        dare4Img,
        dare5Img,
    ],
    Shot: [
        shotImg,
        shot2Img,
        shot3Img,
        shot4Img,
        shot5Img,
    ],
    Backstab: [
        backstabImg,
        backstab2Img,
    ]
}