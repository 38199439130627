import React from 'react';
import { Route } from 'react-router-dom';
import Home from '.';

const route = [
    <Route
        path="/home"
        exact
        component={Home}
        key="/home"
    />,
    <Route
        path="/"
        exact
        component={Home}
        key="/homeslash"
    />
];

export default route;
