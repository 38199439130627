import React from 'react';
import { Route } from 'react-router-dom';
import Room from '.';

const ROUTE_URL = 'room';

export const getRoomRoute = (sessionId) => {
    return `/${ROUTE_URL}/${sessionId}`;
}

const route = (
    <Route
        path={`/${ROUTE_URL}/:id`}
        exact
        component={Room}
        key={`/${ROUTE_URL}/:id`}
    />
);

export default route;
