import {lighten } from 'polished';

export default {
    colors: {
        primary: '#560c84',
        primaryLight: '#8413ca',
        primaryLightest: lighten(0.1, '#8413ca'),
        secondary: '#eca112',
        active: '#0e7525',
        activeLight: lighten(0.1, '#0e7525'),
        disabled: '#bf57ff',
        white: '#ffffff',
        red: '#c74545',
    },
}
