import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Modal,
 } from '../../components';

import TehcImage from '../../../img/tehc.png';

import {
    FakeInfoIcon,
    GoHomeLink,
    ModalContent,
    ModalControls,
    ModalPanel,
    ModalImage,
    Result,
    HeightButton,
    PageHeading,
    PageContainer,
    Calculator,
    CalculatorInputs,
} from './styles';

const About = () => {
    const [showModal, setShowModal] = useState(false);
    const [height, setHeight] = useState(0);
    
    return (
        <PageContainer>
            {showModal && (
                <Modal closeModal={() => setShowModal(false)} title="Tehc">
                    <ModalPanel>
                        <Result>Result</Result>
                        <ModalContent>
                            <FakeInfoIcon>i</FakeInfoIcon>
                            <span>Your height is {height}cm!</span>
                        </ModalContent>
                        <ModalImage>
                            <img alt="tehc" src={TehcImage} />
                        </ModalImage>
                        <ModalControls>
                            <HeightButton onClick={() => setShowModal(false)}>OK</HeightButton>
                        </ModalControls>
                    </ModalPanel>
                </Modal>
            )}
            <PageHeading>Height Calculator Tool</PageHeading>
            <Calculator>
                <CalculatorInputs>
                    <span>Input your height</span>
                    <input
                        onChange={e => setHeight(e.target.value)}
                        type="number"
                    />
                    <span>cm</span>
                    <HeightButton
                        onClick={() => setShowModal(true)}
                        disabled={!height}
                    >Xcm</HeightButton>
                </CalculatorInputs>
            </Calculator>
            <GoHomeLink>
                <Link to="/home">Return home</Link>
            </GoHomeLink>
        </PageContainer>
    )
}

export default About;