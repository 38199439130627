import React from 'react';
import PropTypes from 'prop-types';
import {
    ActionPanelDescription,
    ActionPanelNameAndAction,
    StyledActionPanel,
    WaitToStart,
} from './styles';

const ActionPanel = ({
    player,
    currentAction,
    truth,
    dare,
    targetPlayer,
    drinksText,
}) => {
    if (!currentAction) { 
        return (<WaitToStart>Get ready to play</WaitToStart>);
    };
    return (
        <StyledActionPanel>
            <ActionPanelNameAndAction>
                {player.name}: {currentAction}!
            </ActionPanelNameAndAction>
            {(currentAction === "Dare" || currentAction === "Trick") && (
                <ActionPanelDescription>
                    {dare(targetPlayer.name, drinksText)}
                </ActionPanelDescription>
            )}
            {(currentAction === "Truth" || currentAction === "Treat") && (
                <ActionPanelDescription>
                    {truth(targetPlayer.name, drinksText)}
                </ActionPanelDescription>
            )}
            {currentAction === "Shot" && (
                <ActionPanelDescription>
                    Do a shot bitch!
                </ActionPanelDescription>
            )}
            {currentAction === "Backstab" && (
                <ActionPanelDescription>
                    Choose your victim: they get a shot
                </ActionPanelDescription>
            )}
        </StyledActionPanel>
    );
}

ActionPanel.defaultProps = {
    dare: () => null,
    truth: () => null,
    drinksText: '1 sip',
    targetPlayer: { name: 'No One'},
}

ActionPanel.propTypes = {
    player: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    currentAction: PropTypes.oneOf(['Dare', 'Truth', 'Shot', 'Backstab', 'Treat', 'Trick']),
    truth: PropTypes.func,
    dare: PropTypes.func,
    drinksText: PropTypes.string,
    targetPlayer: PropTypes.shape({
        name: PropTypes.string.isRequired,
    })
}

export default ActionPanel;
