import styled from 'styled-components';

const Button = styled.button`
    border: none;
    font-size: 3.5vw;
    font-family: montserrat;
    border-radius: 5px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.secondary};
    margin: 0.1vw;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${props => props.theme.colors.disabled};
        text-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        cursor: default;
    }
`;
 
export {
    Button,
}
