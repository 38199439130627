import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';

import EyeIcon from '../../../img/eye.svg';

import {
    GalleryContainer,
    GoHomeLink,
    ImagePreview,
    ImagePreviewContainer,
    ImagesContainer,
    PageContainer,
    PackContainer,
    ViewButton,
} from './styles';

import {
    Icon,
} from '../../components/';

import imageIndex from '../room/images';

const About = ({ history, theme }) => {
    const [imagePacks, setImagePacks] = useState([]);

    useEffect(() => {
        let allPacks = [];
        const imagePacks = Object.keys(imageIndex);
        imagePacks.forEach(pack => {
            const imagePack = imageIndex[pack];
            const newPack = {
                name: imagePack.name,
            }
            const truths = imagePack.Truth ? imagePack.Truth : [];
            const dares = imagePack.Dare ? imagePack.Dare : [];
            const treats = imagePack.Treat ? imagePack.Treat : [];
            const tricks = imagePack.Trick ? imagePack.Trick : [];
            newPack.images = [
                imageIndex[pack].Shitebag,
                imageIndex[pack].SpinnerImage,
                ...imageIndex[pack].Shot,
                ...imageIndex[pack].Backstab,
                ...truths,
                ...dares,
                ...treats,
                ...tricks,
            ];
            allPacks.push(newPack);
        })

        setImagePacks(allPacks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer>
            <GalleryContainer>
                {imagePacks.map(imagePack => (
                    <PackContainer key={`imagePackSection-${imagePack.name}`}>
                        <h1>Character Pack: {imagePack.name}</h1>
                        <ImagesContainer>
                            {imagePack.images.map(image => (
                                <ImagePreviewContainer key={image}>
                                    <ImagePreview image={image} />
                                    <ViewButton target="_blank" to={image}>
                                        <Icon
                                            path={EyeIcon}
                                            size="2.5em"
                                            color={theme.colors.primary}
                                        />
                                    </ViewButton>
                                </ImagePreviewContainer>
                            ))}
                        </ImagesContainer>
                    </PackContainer>
                ))}
            </GalleryContainer>
            <GoHomeLink>
                <Link to="/home">Return home</Link>
            </GoHomeLink>
        </PageContainer>
    )
}

export default withTheme(About);