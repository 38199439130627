import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled, { ThemeProvider } from "styled-components";
import HomeRoutes from './pages/home/routes';
import RoomRoutes from './pages/room/routes';
import AboutRoutes from './pages/about/routes';
import HeightRoutes from './pages/height/routes';
import GalleryRoutes from './pages/gallery/routes';
import theme from './theme';

const history = createBrowserHistory();

const NotFoundRoute = <Route path="*" component={() => (<h1>Error: Page not found</h1>)} />;
const VersionNumber = styled.div`
    width: 99%;
    text-align: right;
    padding-right: 1%;
    position: fixed;
    bottom: 0;
    color: ${theme.colors.secondary};
`;

const Routes = () => (
    <ThemeProvider theme={theme}>
        <Router history={history}>
            <Switch>
                {HomeRoutes}
                {RoomRoutes}
                {AboutRoutes}
                {HeightRoutes}
                {GalleryRoutes}
                {NotFoundRoute}
            </Switch>
        </Router>
        <VersionNumber>Version: 1.6.0 - The "Wildcard!" Update</VersionNumber>
    </ThemeProvider>
);

export default Routes;
export { history };
