import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './js/routes';
import './app.css';
import "typeface-montserrat";

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);
