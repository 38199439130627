import React from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
 } from '../../../../components';

import {
    CloseButton,
    ModalControls,
    ModalPanel,
    RulesDescription,
    RulesH1,
    RulesLi,
    RulesSection,
} from './styles';

const RulesModal = ({ closeModal }) => {
    return (
        <Modal title="Rules, motherfucker" closeModal={closeModal}>
            <ModalPanel>
                <RulesSection>
                    <RulesH1>Welcome to Shitebag</RulesH1>
                    <RulesDescription>The rules are simple, but just in case you need a refresher...</RulesDescription>
                    <ul>
                        <RulesLi>
                            <b>Truth and Dare cards</b> - Do what the card says. If you'd rather not, you can redraw using the Shitebag button.
                            If the card involves another player or players, their refusal means they do a shot.
                        </RulesLi>
                        <RulesLi><b>Shot cards</b> - Easy enough, just take a shot.</RulesLi>
                        <RulesLi><b>Backstab cards</b> - Choose someone else to take a shot</RulesLi>
                        <RulesLi><b>Shitebag button</b> - Redraw a a truth or dare card. You can do this as much as you want, but you must do a shot each time</RulesLi>
                    </ul>
                </RulesSection>
                <ModalControls>
                    <CloseButton onClick={closeModal}>Play</CloseButton>
                </ModalControls>
            </ModalPanel>
        </Modal>
    )
}

RulesModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
}

export default RulesModal;
