import styled from 'styled-components';
import { Button } from '../../../../components'

const CloseButton = styled(Button)`
    width: 100%;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.secondary};
`;

const ModalControls = styled.div`
    align-self: flex-end;
    width: 100%;
`;

const ModalPanel = styled.div`
    width: 75vw;
    height: 75vh;
    position: absolute;
    top: 12.5vh;
    left: 11.5vw;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
`;

const RulesDescription = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.5vw;
`;

const RulesH1 = styled.h1`
    color: ${props => props.theme.colors.primary};
    text-align: center;
    font-size: 2.5vw;
`;

const RulesLi = styled.li`
    color: ${props => props.theme.colors.primary};
    font-size: 1vw;
`;

const RulesSection = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;


export {
    CloseButton,
    ModalControls,
    ModalPanel,
    RulesDescription,
    RulesH1,
    RulesLi,
    RulesSection,
}
