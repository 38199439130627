import React from 'react';
import PropTypes from 'prop-types';

import {
    SessionNameContainer,
    SessionNameInput,
} from './styles';

import {
    InputLabel,
} from '../../styles';

const SessionName = ({ setSessionName, sessionName }) => {
    return (
        <SessionNameContainer>
            <InputLabel>Name (optional):</InputLabel>
            <SessionNameInput
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                maxLength="30"
            />
        </SessionNameContainer>
    );
}

SessionName.propTypes = {
    sessionName: PropTypes.string.isRequired,
    setSessionName: PropTypes.func.isRequired,
}

export default SessionName;
