import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { getGalleryRoute } from '../gallery/routes';

import {
    CreateSession,
    LoadSession,
} from './components';
import {
    HomeContainer,
    MenuButton,
} from './styles';

const Home = () => {
    const history = useHistory();

    return (
        <HomeContainer>
            <h1>Shitebag</h1>
            <h2>The best worst drinking game</h2>
            <CreateSession />
            <LoadSession />
            <MenuButton onClick={() => history.push(getGalleryRoute())}>Gallery</MenuButton>
            <MenuButton onClick={() => history.push('/about')}>About</MenuButton>
        </HomeContainer>
    );
};

export default withRouter(Home);
