import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import { getRoomRoute } from '../../../room/routes';

import CreateIcon from '../../../../../img/console.svg';
import {
    Icon,
 } from '../../../../components';
import availableSessionCardPacks from '../../../../cardPacks';

import {
    AddPlayer,
    PlayersList,
    SessionCardPacks,
    SessionCharacterPack,
    SessionName,
} from './components';

import {
    CreateSessionControls,
    ErrorSpan,
} from './styles';

import {
    IconAndText,
    MenuButton,
    SubMenuButton,
    SubMenuContainer,
} from '../../styles';

const characterPacks = [{
    id: 'goffPack',
    label: 'Goffs',
}, {
    id: 'goffAnimalsPack',
    label: 'Animals of the Goff Gaff',
}, {
    id: 'goffChristmasPack',
    label: 'Goff Christmas',
}]

const CreateSession = ({ theme }) => {
    const history = useHistory();
    const [players, setPlayers] = useState([]);
    const [sessionName, setSessionName] = useState('');
    const [showCreateGame, setShowCreateGame] = useState(false);
    const [sessionCardPacks, setSessionCardPacks] = useState(availableSessionCardPacks.map(pack => ({
        id: pack.id,
        title: pack.title,
        description: pack.description,
        selected: 1,
    })));
    const [sessionCharacterPack, setSessionCharacterPack] = useState(characterPacks[0].id);
    const [canStart, setCanStart] = useState(false);
    const [error, setError] = useState('You must add at least one player');

    const checkCanStart = (_players, _sessionCardPacks) => {
        let _canStart = true;
        let _error = '';

        const totalCardPacksSelected = _sessionCardPacks.reduce((selectedTotal, pack) => {
            return selectedTotal + pack.selected;
        }, 0);
        if (totalCardPacksSelected < 1) {
            _canStart = false;
            _error = 'You must select at least one card pack';
        }
        if (_players.length < 1) {
            _canStart = false;
            _error = 'You must add at least one player';
        }
        setCanStart(_canStart);
        setError(_error);
    }

    const addPlayer = (newPlayerName) => {
        const playerToAdd = { id: uuid(), name: newPlayerName };
        const newPlayers = [...players, playerToAdd];
        setPlayers(newPlayers);
        checkCanStart(newPlayers, sessionCardPacks);
    }

    const removePlayer = (playerId) => {
        const newPlayers = players.filter(player => player.id !== playerId);
        setPlayers(newPlayers);
        checkCanStart(newPlayers, sessionCardPacks);
    }

    const startGame = () => {
        const sessionId = uuid();
        const sessionNameToSave = sessionName.length > 0 ? sessionName : uuid();
        const games = localStorage.getItem('games');
        const prevGames = games ? JSON.parse(games) : {};
        const newGames = {
            ...prevGames,
            [sessionId]: {
                players: players,
                name: sessionNameToSave,
                characterPack: sessionCharacterPack,
                cardPacks: sessionCardPacks,
            },
        };
        localStorage.setItem('games', JSON.stringify(newGames));
        history.push(getRoomRoute(sessionId));
    }

    const updateSessionCardPackSelection = (id, numberSelected) => {
        const newCardPackSelections = [...sessionCardPacks];
        const packToUpdateIndex = sessionCardPacks.findIndex((pack => pack.id === id));
        newCardPackSelections[packToUpdateIndex].selected = numberSelected;
        setSessionCardPacks(newCardPackSelections);
        checkCanStart(players, newCardPackSelections);
    }

    return (
        <React.Fragment>
            {!showCreateGame && (
                <MenuButton onClick={() => setShowCreateGame(true)}>
                    <IconAndText>
                        <Icon
                            path={CreateIcon}
                            size="3.5vw"
                            color={theme.colors.primary}
                        />
                        Create Game
                    </IconAndText>
                </MenuButton>
            )}
            {showCreateGame && (
                <SubMenuContainer>
                    <h1>Create Game</h1>
                    <SessionName setSessionName={setSessionName} sessionName={sessionName} />
                    <SessionCharacterPack
                        characterPacks={characterPacks}
                        setSessionCharacterPack={setSessionCharacterPack}
                        sessionCharacterPack={sessionCharacterPack}
                    />
                    <SessionCardPacks
                        sessionCardPacks={sessionCardPacks}
                        updateSessionCardPackSelection={updateSessionCardPackSelection}
                    />
                    <PlayersList removePlayer={removePlayer} players={players} />
                    <AddPlayer addPlayer={addPlayer} />
                    {error && (
                        <ErrorSpan>{error}</ErrorSpan>
                    )}
                    <CreateSessionControls>
                        <SubMenuButton disabled={!canStart} onClick={() => startGame()}>Start</SubMenuButton>
                        <SubMenuButton onClick={() => setShowCreateGame(false)}>Cancel</SubMenuButton>
                    </CreateSessionControls>
                </SubMenuContainer>
            )}
        </React.Fragment>
    )
}

CreateSession.propTypes = {
    theme: PropTypes.shape({}).isRequired,
}

export default withTheme(CreateSession);
