import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
 } from '../../../../../../../../components';
import availableCardPacks from '../../../../../../../../cardPacks';

import {
    CardDisplay,
    CardPackButton,
    CardPacksContainer,
    CardPacksLabel,
    ModalCards,
    ModalControls,
    ModalPanel,
    ModalButton,
    SelectedSpan,
} from './styles';

const CardPackSelector = ({
    cardPackDescription,
    cardPackId,
    cardPackTitle,
    selected,
    setSelected,
}) => {
    const [showModal, setShowModal] = useState(false);
    const indexOfPack = availableCardPacks.findIndex(pack => pack.id === cardPackId);

    const handleIncrement = (e) => {
        setSelected(selected + 1)
        e.stopPropagation();
    }

    const handleDecrement = (e) => {
        setSelected(selected - 1)
        e.stopPropagation();
    }
    
    return (
        <div>
            {showModal && (
                <Modal title="Pack Cards" closeModal={() => setShowModal(false)}>
                    <ModalPanel>
                        <ModalCards>
                            <h1>{cardPackTitle}</h1>
                            <span>{cardPackDescription}</span>
                            <h2>Truth Cards:</h2>
                            {availableCardPacks[indexOfPack].truths.map(card => (
                                <CardDisplay key={card('','')}>{card('<Player>', 'X sips')}</CardDisplay>
                            ))}
                            <h2>Dare Cards:</h2>
                            {availableCardPacks[indexOfPack].dares.map(card => (
                                <CardDisplay key={card('','')}>{card('<Player>', 'X sips')}</CardDisplay>
                            ))}
                        </ModalCards>
                        <ModalControls>
                            <ModalButton onClick={() => setShowModal(false)}>Close</ModalButton>
                        </ModalControls>
                    </ModalPanel>
                </Modal>
            )}
            <CardPacksContainer onClick={() => setShowModal(true)}>
                <CardPacksLabel>{cardPackTitle}</CardPacksLabel>
                <CardPackButton disabled={selected < 1} onClick={handleDecrement}>-</CardPackButton>
                <SelectedSpan>{selected}</SelectedSpan>
                <CardPackButton onClick={handleIncrement}>+</CardPackButton>
            </CardPacksContainer>
        </div>
    )
}

CardPackSelector.propTypes = {
    cardPackDescription: PropTypes.string.isRequired,
    cardPackId: PropTypes.string.isRequired,
    cardPackTitle: PropTypes.string.isRequired,
    selected: PropTypes.number.isRequired,
    setSelected: PropTypes.func.isRequired,
}

export default CardPackSelector;
