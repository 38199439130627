import React from 'react';
import { Link } from 'react-router-dom';

import {
    GoHomeLink,
    Credits,
    DevCredit,
    IconCredit,
    TheBeach,
} from './styles';
import BeachImage from '../../../img/the-only-good-thing-about-lockdown.png'

const About = () => (
    <div>
        <Credits>
            <h1>Shitebag - The Best Worst Drinking Game</h1>
            <DevCredit>
                Lead Developer: Bryan Miller
            </DevCredit>
            <DevCredit>
                Frontend Developer: Tom Lilley
            </DevCredit>
            <DevCredit>
                Responsiveness Developer: Cameron Findlay
            </DevCredit>
            <DevCredit>
                Lead Designer: Bryan Miller
            </DevCredit>
            <DevCredit>
                Design Consultant:  Rick Simpson
            </DevCredit>
            <DevCredit>
                Lead Stunt Choreography: Bryan Miller
            </DevCredit>
            <IconCredit>
                Icons made by <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </IconCredit>
            <IconCredit>
                Icons made by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </IconCredit>
            <IconCredit>
                Icons made by <a href="https://www.flaticon.com/authors/icongeek26" title="Icongeek26">Icongeek26</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </IconCredit>
            <IconCredit>
                Icons made by <a href="https://www.flaticon.com/authors/vitaly-gorbachev" title="Vitaly Gorbachev">Vitaly Gorbachev</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </IconCredit>
            <GoHomeLink>
                <Link to="/home">Return home</Link>
            </GoHomeLink>
        </Credits>
        <TheBeach>
            <img
                alt="beach"
                src={BeachImage}
            />
        </TheBeach>
    </div>
)

export default About;